<template>
  <div>
      <div class="loading-container" :class="slow" v-if="loadingPage">
        <div class="loading-wrapper">
          <div class="bg-container">
            <img src="../src/assets/imgs/loading-background.png" />
          </div>
          <div class="logo-container">
            <img src="../src/assets/imgs/loading-logo.png" />
          </div>
        </div>
      </div>
    <div id="app" class="app" v-if="userFounded">
      <router-view class="container" :data="user" :userEmail="userEmail" :updatedLinksHandle="updatedLinksHandle" />
    </div>
    <div class="error-container" v-else-if="userNotFounded">
      <p class="error-msg">{{ $t("error_msg") }}</p>
    </div>
    </div>
</template>

<script>
/* eslint-disable */

// import i18n from "./main"
const {
  gsCountryCodeID,
  getGoogleSheetsData,
} = require("./actions/onLoadActions");

export default {
  name: "App",
  data() {
    return {
      userEmail: null,
      userFounded: false,
      userNotFounded: false,
      user: {},
      loadingPage: false,
      slow: "",
    }
  },
  methods: {
    updatedLinksHandle(newLinksObj) {
      this.user.allLinks = newLinksObj;
    }
  },
  async created() {

    const mainScoop = this;

    /* === Google Login === */

    let client = window.google.accounts.oauth2.initTokenClient({
      client_id: "274863605283-9pf4rjanmqnfflkb30njlgho168frkq2.apps.googleusercontent.com",
      scope: 'profile email',
      ux_mode: 'popup',
      prompt_parent_id: 'app',
      prompt: "",
      access_type: 'offline',
      callback: async (res) => {

        mainScoop.slow = "transition-appear";
        mainScoop.loadingPage = true;

        /* === Decoding user information and assign the value to a variable === */
        const gResponse = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${res.access_token}`);
        const userInfo = await gResponse.json();

        /* ======================================== */

        /* === Get user's data === */
        async function requestData() {
          try {

            // Get user's email from google login
            const email = userInfo.email;

            // =================================

            // Call user's API
            let userDataResponse = await fetch(`/api/user/auth/${email}`, {
              method: 'get',
            });
            const userData = await userDataResponse.json();

            // console.log(userData);

            // =================================

            // Check if the user exist
            // Then start with the process to display his data on the page

            if (userData.zohoUserValid) {

              /* ===== Create "Add Link" element to the user links array ===== */

              userData.links.push({
                frontSide_icon_name: "add-link.png",
                link_group: "own_links",
                link_name: "",
              })
              mainScoop.user.userInformations = userData;

              // =================================


              /* ===== Get Google sheets's data ===== */

              // Function to Get Tip or Event

              const getTipEvent = (eventsArr, tipsArr) => {
                const currentDate = new Date();
                const today =
                  currentDate.getDate() +
                  "-" +
                  (currentDate.getMonth() + 1) +
                  "-" +
                  currentDate.getFullYear();
                let allEvents;
                let data;

                // get event / tip
                if (eventsArr.length > 0) {
                  allEvents = eventsArr.filter(event => {
                    return event.start_date <= today && event.end_date >= today && event.start_date != "";
                  });
                }

                // get event
                // Check if there are already events
                if (allEvents && allEvents.length > 0) {
                  let sortedDates;
                  // Check if there are multi active events
                  if (allEvents.length > 1) {
                    // Sort the active events by the newest date
                    sortedDates = allEvents.sort((a, b) => {
                      return a.start_date - b.satrt_date;
                    });
                    if (sortedDates[0].link_url != "" && sortedDates[0].link_url != undefined) {
                      data = {
                        text: sortedDates[0].text,
                        isText: true,
                        url: sortedDates[0].link_url,
                        isURL: true
                      };
                    }
                    else {
                      data = {
                        text: sortedDates[0].text,
                        isText: true,
                        isURL: false
                      };
                    }

                  }
                  else {
                    if (allEvents[0].link_url != "" && allEvents[0].link_url != undefined) {
                      data = {
                        text: allEvents[0].text,
                        isText: true,
                        url: allEvents[0].link_url,
                        isURL: true
                      };
                    }
                    else {
                      data = {
                        text: allEvents[0].text,
                        isText: true,
                        isURL: false
                      };
                    }
                  }

                }
                else {
                  // get random tip
                  if (tipsArr.length > 0) {
                    const randomNumber = Math.floor(Math.random() * tipsArr.length);
                    if (tipsArr[randomNumber].link_url != "" && tipsArr[randomNumber].link_url != undefined) {
                      data = {
                        text: tipsArr[randomNumber].text,
                        isText: true,
                        url: tipsArr[randomNumber].link_url,
                        isURL: true
                      };
                    }
                    else {
                      data = {
                        text: tipsArr[randomNumber].text,
                        isText: true,
                        isURL: false
                      };
                    }
                  } else {
                    data = {
                      text: "There is not tip or event for today",
                      isText: false
                    };
                  }
                }
                

                let word;
                let linkWord;
                let tipEventLink;
                // Check if the tip or the event has an URL
                if (data.isURL) {
                  // Check if the the text has the characters "<" and ">"
                  if (data.text.includes("<") && data.text.includes(">")) {
                    // Get the start and the end of the link's word with the characters "<" and ">"
                    const startLink = data.text.indexOf('<');
                    const endLink = data.text.indexOf('>') + 1;
                    // Get the link's word
                    linkWord = data.text.substring(startLink, endLink);
                    // Get the link's word without the characters "<" and ">"
                    word = linkWord.substring(1, linkWord.length - 1);
                    // Check if the characters "<" and ">" are in the right position
                    if (linkWord === `<${word}>`) {
                      // Create the link
                      tipEventLink = `<a href="${data.url}" class="tip-event-word-link" target="_blank">${word}</a>`
                      // Remove the characters "<" and ">" from the text the will be displayed
                      const newText = data.text.replace(linkWord, tipEventLink);
                      data.text = newText;
                    }
                    else {  // the characters "<" and ">" are not in the right position
                      // Remove the characters "<" and ">" from the text the will be displayed
                      const newText = data.text.replace(/<|>/g, '');
                      data.text = newText;
                    }
                  }
                }
                return data;
              };

              // console.log(getTipEvent);

              // Function to get the links by role
              const getLinksByRole = (role, userEmail, gsLinks) => {

                let allLinks = {};
                Object.keys(gsLinks).forEach((linksArr) => {
                  allLinks[linksArr] = [];
                  gsLinks[linksArr].forEach((link, index) => {
                    Object.keys(link).forEach((key) => {
                      if (role === key && link[key] === "TRUE") {
                        allLinks[linksArr].push(gsLinks[linksArr][index]);
                      }
                      else if (key === "another_user" && link[key] === "TRUE" && link["user_email"].includes(userEmail)) {
                        allLinks[linksArr].push(gsLinks[linksArr][index]);
                      }
                    })
                  })
                })

                return allLinks;
              }

              // console.log(getLinksByRole);

              // Call Google Sheets

              // Get Sheets of country code & ID
              const getCountryCodesID = await gsCountryCodeID();
              // console.log(getCountryCodesID);

              const countryCode_id = getCountryCodesID.filter(
                (country) => country.country_code === userData.lang
              );

              // console.log(countryCode_id);

              let googleSheetsData;
              if (typeof countryCode_id !== "undefined" && countryCode_id.length > 0) {
                const sheets_id = countryCode_id[0].sheets_id;
                const gsRes = await fetch(`/api/googleSheet/gsData/${sheets_id}`, {
                  method: "get"
                });

                googleSheetsData = await gsRes.json();
                // console.log(googleSheetsData);
              }

              let gsData = getGoogleSheetsData(googleSheetsData, userData.email, userData.module, userData.role);

              // =================================

              /* ===== Filter the links by role to display them ===== */

              const userRole = userData.role;
              const userEmail = userData.email;
              let gsLinks = gsData.links;
              let role;
              let allLinks= {};

              // console.log("userRole: " + userRole);

              if (userRole.includes("Trainer")) {
                role = "SS";
              }
              else if (userRole.includes("Studio") && !userRole.includes("FO")) {
                role = "ST";
              }
              else if (userRole.includes("FO") && !userRole.includes("Studio")) {
                role = "FO";
              }
              else if (userRole.includes("MF")) {
                role = "MF";
              }
              else if (userRole === "fit20 Group") {
                allLinks = gsLinks;
              }

              // console.log("role: " + role);

              if(role) {
                allLinks = getLinksByRole(role, userEmail, gsLinks);
              }

              // console.log(allLinks);

              // =================================


              /* ===== Get all icons images ===== */

              const getImgsNames = await fetch(`/api/iconsPhotos`);

              const imgsNames = await getImgsNames.json();

              mainScoop.user.imgsNames = imgsNames;

              // console.log(imgsNames);

              // =================================


              /* ===== Get Tip / Event ===== */
              
              const tipsEvents = getTipEvent(
                gsData.tips_events.eventsArr,
                gsData.tips_events.tipsArr
              );

              mainScoop.user.tipsEvents = tipsEvents;

              // =================================


              /* ===== Get Translate ===== */

              if (gsData.translate) {
                const translate = gsData.translate;
                const userLanguage = Object.keys(translate)[0];
                mainScoop.$i18n.locale = userLanguage;
                mainScoop.$i18n.setLocaleMessage(mainScoop.$i18n.locale, translate[mainScoop.$i18n.locale]);
              }

              // =================================


              /* ===== Get Calendars ===== */

              mainScoop.user.calendars = gsData.calendars;


              // =================================


              /* ===== Add user's links to the category "Other" ===== */

              Object.keys(allLinks).forEach(linksArr => {
                userData.links.forEach(link => {
                  if(linksArr == "category_04LinksArr") {
                    allLinks[linksArr].push(link);
                  }
                })
              });

              mainScoop.user.allLinks = allLinks;

              // =================================


              /* === Change the value of userEmail from null to the email that the user signed in with === */

              mainScoop.userEmail = userData.email;
              mainScoop.userFounded = true;
              mainScoop.loadingPage = false;
            }
            else {
              mainScoop.$i18n.setLocaleMessage(mainScoop.$i18n.locale, mainScoop.$i18n.messages);
              mainScoop.userNotFounded = true;
            }
          }
          catch (e) {
            console.log(e);
            mainScoop.$i18n.setLocaleMessage(mainScoop.$i18n.locale, mainScoop.$i18n.messages);
            mainScoop.userFounded = false;
            mainScoop.loadingPage = false;
            mainScoop.userNotFounded = true;
          };
        };
        await requestData();
        // console.log(mainScoop.user);
      }
    });
    client.requestAccessToken();
  },
};

</script>


<style>
@font-face {
  font-family: 'VAG Rounded Std';
  src: url('./assets/font/rond-heavy.woff2') format('woff2');
}
@font-face {
  font-family: 'VAG Rounded light';
  src: url('./assets/font/rond-light.woff2') format('woff2');
}

@font-face {
  font-family: 'OpenSans Light';
  src: url('./assets/font/opensans-light.woff2') format('woff2');
}
@font-face {
  font-family: 'OpenSans Std';
  src: url('./assets/font/opensans-regular.woff2') format('woff2');
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(15px);
  }

  50% {
    transform: translateY(-5px);
  }

  75% {
    transform: translateY(5px);
  }
  
  100% {
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

body {
  width: 100%;
  position: relative;
}

.app {
  width: 100%;
  overflow: hidden;
  animation: fade-in 2500ms forwards;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  animation: fade-in 2000ms forwards;
}

.loading-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.bg-container img {
  width: 100%;
}

.logo-container {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 99;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  width: 100px;
  animation: jump 1.25s ease-in infinite;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}

.h1 {
  font-size: 2rem;
  font-weight: bold;
}

.icon-eye-border-right {
  border-radius: 10px 0;
}

.icon-eye-border-left {
  border-radius: 0 10px;
}

.hidden {
  display: none !important;
}

.slow-appear {
  animation: fade-in 500ms forwards;
}

.slow-closing {
  animation: fade-out 500ms forwards;
}

.close-btn {
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.close-btn:hover {
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  cursor: pointer;
  filter: invert(53%) sepia(86%) saturate(4158%) hue-rotate(175deg) brightness(101%) contrast(100%);
}


/* ============================ */


/* Works on Firefox */
.scroll-bar {
  scrollbar-width: thin;
  cursor: pointer;
}

.scroll-bar {
  scrollbar-width: thin;
  cursor: pointer;
}

/* Works on Chrome, Edge, and Safari */
.scroll-bar::-webkit-scrollbar {
  width: 6px;
}

.scroll-bar::-webkit-scrollbar-track {
  background: #fff;
}

.scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 20px;
}

.green {
   scrollbar-color: #92d400 #fff;
}

.green::-webkit-scrollbar-thumb {
  background-color: #92d400;
}

.blue {
  scrollbar-color: #009df4 #fff;
}

.blue::-webkit-scrollbar-thumb {
  background-color: #009df4;
}


/* ============================ */

.app {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #2c3e50;
  width: 100%;
}

.tip-event-word-link {
    font-size: 12px;
    font-weight: bold;
    color: #92d400 !important;
}

.tip-event-word-link:hover {
  text-decoration: underline;
}

.error-container {
  width: 100%;
  animation: fade-in 2500ms forwards;
}

.error-msg {
    width: max-content;
    font-family: "VAG Rounded light", Verdana, Geneva, Tahoma, sans-serif;
    color: #92d400;
    margin: auto;
    font-weight: bold;
    font-size: 24px;
    padding-top: 20%;
}


@media only screen and (max-width: 950px) {
  .error-msg {
    font-size: 24px;
    padding-top: 40%;
  }
  
}

@media only screen and (max-width: 650px) {
  .error-msg {
    font-size: 24px;
    padding-top: 50%;
  }
}

</style>
